/* Global */
* {
  font-family: 'Work Sans', sans-serif;
}

h1, h2, h3, p  {
  color: #FFFFFF;
}

[data-scroll] {
  transition: opacity 1s;
}

[data-scroll="in"] {
  opacity: 1;
}

[data-scroll="out"] {
  opacity: 0;
}

html, body, #root, .App {
  height: 100%;
}

.kn-full-height-container {
  height: 100%;
}
/* Global END */

/* Header */
#kn-logo  {
  padding: 0px;
}

.kn-header-container  {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.kn-header  {
  background-color: #F4F5F7;
}
/* Header END */

/* Introduction */
.kn-container {
  position: relative;
  margin: 0px;
  width: 100%;
}

#kn-gmail {
  margin-right: 20px;
}

.kn-jumbotron  {
  background: none;
  margin: 0px;
}

.btn-primary {
  background-color: #FBA540;
  border-color: #FBA540;
}

.kn-headshot-container  {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 992px) {
  .kn-headshot-container {
    display: none;
  }
}

.kn-introduction-row  {
  background: rgb(25,26,77);
  background: linear-gradient(90deg, rgba(25,26,77,1) 48%, rgba(25,69,77,1) 100%, rgba(169,215,236,1) 100%);
}

.typed-playground {
  color: #FFFFFF;
}

.kn-canvas  {
  position: absolute;
  /* A width of 100% here causes the canvas element to overflow and generate a horizontal scroll bar */
  width: 95%;
  height: 100%;
  color: black;
}
/* Introduction END */

/* Divider */
.kn-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 100px;
}
/* Divider END */

/* Work Experience */
.vertical-timeline-element-title  {
  color: #000000;
}

.kn-work-ex {
  display: flex;
  justify-content: center;
  margin: 0px;
  background: #F4F5F7;
}

@media (min-width: 992px) {
  .kn-work-ex {
    background: url('./SVG/work-experience.svg') no-repeat;
    background-size: cover;
  }
}
/* Work Experience END */

/* Projects */
/* .kn-project-container {
  background: url('./SVG/projects-background.svg') no-repeat;
  background-size: cover;
  margin: 0px;
  padding-left: 370px;
  padding-right: 370px;
} */

.card-text  {
  color: #000000;
}

.kn-project-card-row  {
  margin-top: 20px;
  margin-bottom: 20px;
}

.kn-project-card-col  {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Projects END */

/* Certifications */
.kn-badge-container {
  background: url('./SVG/certifications-background.svg') no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 20px;
}

.kn-badge-col  {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
/* Certifications END */

/* My GEO */
.kn-h1  {
  color: #353A3F;
}

.kn-geo-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* My GEO END */

/* Footer */
.kn-footer-container  {
  /* display: flex; */
  /* justify-content: center; */
  background-color: #F4F5F7;
  /* padding-top: 20px;
  padding-bottom: 20px; */
}

.kn-footer-container-1  {
  display: flex;
  justify-content: center;
  background-color: #F4F5F7;
  padding-top: 20px;
  padding-bottom: 5px;
}

.kn-footer-container-2   {
  display: flex;
  justify-content: center;
  background-color: #F4F5F7;
  padding-top: 5px;
  padding-bottom: 20px;
}

.kn-footer-row  {
  width: 80%;
}

.kn-footer-col  {
  text-align: center;
}
/* Footer END */
